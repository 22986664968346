@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.quiz-message {
  font-family: 'Outfit', sans-serif;
  font-size: 1.2em;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
}

.page-title {
  font-family: 'Outfit', sans-serif;
  font-size: 2.8em;
  color: #2d1b69;
  text-align: center;
  margin: 2rem 0;
  font-weight: 700;
  letter-spacing: -0.03em;
}

body {
  font-family: 'DM Sans', sans-serif;
  background-color: #faf8ff;
  margin: 0;
  padding: 0;
  color: #2d1b69;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.welcome-message {
  font-family: 'Outfit', sans-serif;
  font-size: 1.8em;
  color: #6d28d9;
  text-align: center;
  font-weight: 600;
}

.nav-item {
  font-family: 'Outfit', sans-serif;
  font-size: 1.3em;
  font-weight: 500;
}

body.dark-mode {
  background-color: #13111C;
  color: #e9e8ff;
}

body.dark-mode .page-title {
  color: #e9e8ff;
}

.mode-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toggle-button {
  background: linear-gradient(135deg, #7c3aed 0%, #c026d3 100%);
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.3s ease;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}

.toggle-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.3);
}

body.dark-mode .toggle-button {
  background: linear-gradient(135deg, #6d28d9 0%, #a21caf 100%);
}

.navigation {
  background: linear-gradient(135deg, #7c3aed 0%, #c026d3 100%);
  padding: 1.2rem 0;
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.2);
}

body.dark-mode .navigation {
  background: linear-gradient(135deg, #6d28d9 0%, #a21caf 100%);
  box-shadow: 0 4px 20px rgba(109, 40, 217, 0.2);
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  margin: 0;
}

.nav-link {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5em;
  transition: all 0.3s ease;
  padding: 0.6rem 1.2rem;
  border-radius: 12px;
  font-weight: 500;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

.nav-link-word {
  font-family: 'Outfit', sans-serif;
  text-decoration: none;
  color: #2d1b69;
  font-size: 1.5em;
  transition: all 0.3s ease;
  font-weight: 500;
}

.nav-link-word:hover {
  color: #7c3aed;
}

body.dark-mode .nav-link-word {
  color: #e9e8ff;
}

body.dark-mode .nav-link-word:hover {
  color: #a78bfa;
}

.Home-nav-link-word {
  text-decoration: none;
  color: #2d1b69;
  font-size: 0.9em;
  transition: all 0.3s ease;
  font-weight: 500;
}

.Home-nav-link-word:hover {
  color: #7c3aed;
}

body.dark-mode .Home-nav-link-word {
  color: #e9e8ff;
}

body.dark-mode .Home-nav-link-word:hover {
  color: #a78bfa;
}

.home-container {
  max-width: 900px;
  margin: 2rem 20rem auto;
  background: #ffffff;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.1);
  border-radius: 24px;
}

body.dark-mode .home-container {
  background: #1a1625;
  box-shadow: 0 4px 20px rgba(109, 40, 217, 0.1);
}

body.dark-mode .welcome-message {
  color: #a78bfa;
}

.vocabulary-container {
  max-width: 1100px;
  margin: 2rem 20rem auto;
  background: #ffffff;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.1);
  border-radius: 24px;
}

body.dark-mode .vocabulary-container {
  background: #1a1625;
  box-shadow: 0 4px 20px rgba(109, 40, 217, 0.1);
}

.search-input {
  width: 90%;
  padding: 1rem 1.5rem;
  font-size: 1.2em;
  border: 2px solid #e9e8ff;
  border-radius: 16px;
  margin: 0 auto 25px;
  display: block;
  transition: all 0.3s ease;
  font-family: 'DM Sans', sans-serif;
}

.search-input:focus {
  outline: none;
  border-color: #7c3aed;
  box-shadow: 0 0 0 4px rgba(124, 58, 237, 0.1);
}

body.dark-mode .search-input {
  background-color: #2d1b69;
  border-color: #4c1d95;
  color: #e9e8ff;
}

.word-table, .word-table2 {
  width: 100%;
  border-spacing: 0;
  margin-top: 20px;
  border-radius: 16px;
  overflow: hidden;
}

.table-header, .table-header2 {
  background: linear-gradient(135deg, #7c3aed 0%, #c026d3 100%);
  color: white;
  padding: 1.2rem;
  text-align: left;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
}

body.dark-mode .table-header,
body.dark-mode .table-header2 {
  background: linear-gradient(135deg, #6d28d9 0%, #a21caf 100%);
}

.table-row:nth-child(even),
.table-row2:nth-child(even) {
  background-color: #faf8ff;
}

body.dark-mode .table-row:nth-child(even),
body.dark-mode .table-row2:nth-child(even) {
  background-color: #2d1b69;
}

.table-cell, .table-cell2 {
  padding: 1.2rem;
  border-bottom: 1px solid #e9e8ff;
}

body.dark-mode .table-cell,
body.dark-mode .table-cell2 {
  border-bottom: 1px solid #4c1d95;
}

.favorite-button, .favorite-button2 {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.8em;
  color: #e11d48;
  transition: all 0.3s ease;
}

body.dark-mode .favorite-button,
body.dark-mode .favorite-button2 {
  color: #fb7185;
}

.favorite-button:hover,
.favorite-button2:hover {
  transform: scale(1.3);
  color: #be123c;
}

.favorites-container {
  max-width: 1100px;
  margin: 2rem auto;
  background: #ffffff;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.1);
  border-radius: 24px;
}

body.dark-mode .favorites-container {
  background: #1a1625;
  box-shadow: 0 4px 20px rgba(109, 40, 217, 0.1);
}

.quiz-container {
  max-width: 700px;
  margin: 2rem 20rem auto;
  background: #ffffff;
  padding: 2.5rem;
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.1);
  border-radius: 24px;
}

body.dark-mode .quiz-container {
  background: #1a1625;
  box-shadow: 0 4px 20px rgba(109, 40, 217, 0.1);
}

.quiz-content {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.word-meaning {
  font-size: 2.2em;
  color: #7c3aed;
  margin: 2rem 0;
  font-weight: bold;
  font-family: 'Outfit', sans-serif;
}

body.dark-mode .word-meaning {
  color: #a78bfa;
}

.quiz-form {
  margin-bottom: 20px;
}

.quiz-input {
  padding: 1rem 1.5rem;
  font-size: 1.2em;
  width: 80%;
  border: 2px solid #e9e8ff;
  border-radius: 16px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  font-family: 'DM Sans', sans-serif;
}

body.dark-mode .quiz-input {
  background-color: #2d1b69;
  border-color: #4c1d95;
  color: #e9e8ff;
}

.submit-button {
  font-family: 'Outfit', sans-serif;
  background: linear-gradient(135deg, #7c3aed 0%, #c026d3 100%);
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 16px;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
  font-weight: 600;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(124, 58, 237, 0.3);
}

body.dark-mode .submit-button {
  background: linear-gradient(135deg, #6d28d9 0%, #a21caf 100%);
}

.score {
  font-size: 1.5em;
  color: #7c3aed;
  font-weight: bold;
  font-family: 'Outfit', sans-serif;
}

body.dark-mode .score {
  color: #a78bfa;
}

.footer {
  background: linear-gradient(135deg, #7c3aed 0%, #c026d3 100%);
  color: white;
  text-align: center;
  padding: 1.8rem;
  margin-top: auto;
  font-family: 'DM Sans', sans-serif;
}

body.dark-mode .footer {
  background: linear-gradient(135deg, #6d28d9 0%, #a21caf 100%);
}

.logo {
  display: block;
  margin: 0 auto;
}

.text-bottom {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
  font-family: 'DM Sans', sans-serif;
}